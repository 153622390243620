import * as React from "react";
import PageComponent from "src/components/pages/learn/what-is-life-insurance";

const OurTeamPage = ({ location }) => {
  const copy = {
    text: `ביטוח חיים הוא חוזה שמבטיח להעניק נזילות כלכלית בעת מותו של המבוטח כל זמן שהפוליסה שלו בתוקף. הפרמיה משולמת למבטח שהנפיק את החוזה, בדרך כלל חברת ביטוח חיים, וכל זמן שהפוליסה בתוקף המבטח ישלם את התמורה הכלכלית המובטחת למוטב ששמו מופיע בחוזה, בעת הפטירה של המבוטח.`,
  };
  return (
    <PageComponent
      lang="he"
      location={location}
      copy={copy}
      pageTitle="מהו ביטוח חיים?"
    />
  );
};

export default OurTeamPage;
